export default [
  {
    label: "home",
    url: "/",
  },
  {
    label: "our services",
    url: "/services",
  },
  {
    label: "gallery",
    url: "/gallery",
  },
  {
    label: "contact us",
    url: "/contact",
  },
]
